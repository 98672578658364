import firebase from 'firebase/app';
import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash';
import dayjs from 'dayjs';
import { activity as activityStatus } from '@/helpers/status';
import dialog from './dialog';

const defaultFormData = {
  status: activityStatus.published.value,
  activityType: 'COURSE',
  locationType: 'LOCATION',
  title: {
    DE: '',
  },
  surrounding: '',
  ages: [],
  parents: 'NONE',
  buggyFriendly: true,
  description: {
    DE: '',
  },
  costFree: true,
  costDescription: {
    DE: '',
  },
  ticketUrl: '',
  category: '',
  subCategory: '',
  venue: '',
  venueName: '',
  venueAddress: '',
  venueZip: '',
  venueCity: '',
  dates: null,
  highlight: false,
  urls: [],
  images: [],
};

export default {
  namespaced: true,
  modules: { dialog },
  state: {
    listenerUnsubscribe: [],
    loading: false,
    activity: null,
    activities: [],
    form: _.cloneDeep(defaultFormData),
  },
  mutations: {
    SET_LISTENER_UNSUBSCRIBE(state, payload) {
      state.listenerUnsubscribe.push(payload);
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setAll(state, payload) {
      state.activities = payload;
    },
    resetAll(state) {
      state.activities = [];
      state.listenerUnsubscribe.forEach((store) => {
        store();
      });
      state.listenerUnsubscribe = [];
    },
    set(state, payload) {
      state.activity = payload;
    },
    sync(state) {
      state.form = {
        status: state.activity.status,
        activityType: state.activity.activityType,
        locationType: state.activity.locationType,
        title: state.activity.title,
        surrounding: state.activity.surrounding,
        ages: state.activity.ages,
        parents: state.activity.parents,
        buggyFriendly: state.activity.buggyFriendly,
        description: state.activity.description,
        costFree: state.activity.costFree,
        costDescription: state.activity.costDescription,
        ticketUrl: state.activity.ticketUrl,
        category: state.activity.category,
        subCategory: state.activity.subCategory,
        venue: state.activity.venue,
        venueName: '',
        venueAddress: '',
        venueZip: '',
        venueCity: '',
        dates: state.activity.dates,
        highlight: state.activity.highlight,
        urls: state.activity.urls || [],
        images: state.activity.images,
      };
      // state.form = _.cloneDeep(state.activity);
    },
    reset(state) {
      state.activity = null;
      state.form = _.cloneDeep(defaultFormData);
    },
    updateField(state, field) {
      updateField(state.form, field);
    },
  },
  actions: {
    async loadAll({ commit }) {
      commit('setLoading', true);
      const organizations = {};
      const organizationsData = await firebase.firestore().collection('organisations').get();
      organizationsData.forEach((doc) => {
        organizations[doc.id] = { id: doc.id, path: doc.ref.path, ...doc.data() };
      });

      const categories = {};
      const categoriesData = await firebase.firestore().collection('categories').get();
      categoriesData.forEach((doc) => {
        categories[doc.id] = { id: doc.id, path: doc.ref.path, ...doc.data() };
      });

      const users = {};
      const usersData = await firebase.firestore().collection('users').get();
      usersData.forEach((doc) => {
        users[doc.id] = { id: doc.id, path: doc.ref.path, ...doc.data() };
      });

      // eslint-disable-next-line max-len
      const unsubscribe = firebase.firestore().collection('activities').where('status', 'in', [activityStatus.draft.value, activityStatus.review.value, activityStatus.published.value]).onSnapshot((snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          const activity = {
            _type: 'Activity', id: doc.id, path: doc.ref.path, ...doc.data(),
          };

          if (activity.organisation) {
            activity.organisation = organizations[activity.organisation.id];
          }
          if (activity.category) {
            activity.category = categories[activity.category.id];
          }
          if (activity.createdBy) {
            activity.createdBy = users[activity.createdBy.id];
          }
          if (activity.updatedBy) {
            activity.updatedBy = users[activity.updatedBy.id];
          }
          if (!activity.dates) {
            activity.dates = { last: null };
          }
          activity.images = !!(activity.images && activity.images.length > 0);

          data.push(activity);
        });
        commit('setAll', data);
        commit('setLoading', false);
      });
      commit('SET_LISTENER_UNSUBSCRIBE', unsubscribe);
    },
    loadAllByOrganisation({ commit, rootGetters }) {
      commit('setLoading', true);
      const unsubscribe = firebase.firestore().collection('activities')
        .where('organisation', '==', firebase.firestore().doc(rootGetters['organization/get'].path))
        .where('status', 'in', [activityStatus.draft.value, activityStatus.review.value, activityStatus.published.value])
        .onSnapshot((snapshot) => {
          const data = [];
          snapshot.forEach((doc) => {
            const activity = {
              _type: 'Activity', id: doc.id, path: doc.ref.path, ...doc.data(),
            };
            if (activity.category) {
              activity.category.get().then((snap) => {
                activity.category = { id: snap.id, path: snap.ref.path, ...snap.data() };
              });
            }

            const images = [];
            if (activity.images) {
              activity.images.forEach((image) => {
                image.get().then((snap) => {
                  images.push({ id: snap.id, path: snap.ref.path, ...snap.data() });
                });
              });
            }
            activity.images = images;

            if (activity.dates) {
              activity.canBePublished = activity.dates.last === null || (activity.dates.last && dayjs(activity.dates.last.toDate()).isAfter(dayjs()));
            }

            data.push(activity);
          });
          commit('setAll', data);
          commit('setLoading', false);
        });
      commit('SET_LISTENER_UNSUBSCRIBE', unsubscribe);
    },
    async load({ commit }, payload) {
      try {
        const doc = await firebase.firestore().collection('activities').doc(payload).get();

        const {
          category, subCategory, venue, dates, ...data
        } = doc.data();

        const activity = {
          _type: 'Activity',
          id: doc.id,
          path: doc.ref.path,
          ...data,
        };

        if (category) {
          activity.category = category.path;
        }

        if (subCategory) {
          activity.subCategory = subCategory.path;
        }

        if (venue) {
          activity.venue = venue.path;
        }

        const images = [];
        if (activity.images) {
          activity.images.forEach((image) => {
            image.get().then((snap) => {
              const { alternativeText, ...rest } = snap.data();
              let alt = alternativeText;
              if (!alt) {
                alt = { DE: '' };
              }

              images.push({
                id: snap.id, path: snap.ref.path, alternativeText: alt, ...rest,
              });
            });
          });
        }
        activity.images = images;

        if (dates) {
          activity.dates = dates;

          // eslint-disable-next-line default-case
          switch (dates.type) {
            case 'SINGLE': {
              activity.dates.data.date = dates.data.date.toDate();
              break;
            }
            case 'MULTIPLE': {
              activity.dates.data.dates = dates.data.dates.map(({ date, ...rest }) => ({ date: date.toDate(), ...rest }));
              break;
            }
            case 'RECURRING': {
              activity.dates.data.rule.dtstart = dates.data.rule.dtstart.toDate();
              activity.dates.data.rule.until = dates.data.rule.until.toDate();
              activity.dates.data.exceptionDates = dates.data.exceptionDates.map((date) => date.toDate());
              break;
            }
            case 'OPENINGTIMES': {
              activity.dates.data.exceptionDates = dates.data.exceptionDates.map((date) => date.toDate());
              if (activity.dates.data && activity.dates.data.dtstart) {
                activity.dates.data.dtstart = activity.dates.data.dtstart.toDate();
              }
              if (activity.dates.data && activity.dates.data.until) {
                activity.dates.data.until = activity.dates.data.until.toDate();
              }
              break;
            }
            case 'REQUEST': {
              if (activity.dates.data && activity.dates.data.dtstart) {
                activity.dates.data.dtstart = activity.dates.data.dtstart.toDate();
              }
              if (activity.dates.data && activity.dates.data.until) {
                activity.dates.data.until = activity.dates.data.until.toDate();
              }
              break;
            }
          }

          if (activity.dates.data && activity.dates.data.deadline && activity.dates.data.deadline.type === 'DATE') {
            activity.dates.data.deadline.value = activity.dates.data.deadline.value.toDate();
          }
        }

        commit('set', activity);
        return activity;
      } catch (error) {
        commit('error/set', error, { root: true });
      }
      return null;
    },
    async create({ commit, rootGetters }, payload) {
      commit('loading/set', true, { root: true });

      try {
        const {
          category, subCategory, venue, venueName, venueAddress, venueZip, venueCity, images, urls, ...rest
        } = payload;

        let venuePath = venue;

        if (venue && venue === 'NEW') {
          const newVenue = await firebase.firestore().collection('venues').add({
            organisation: firebase.firestore().doc(rootGetters['organization/get'].path),
            createdBy: firebase.firestore().doc(rootGetters['auth/get'].path),
            createdAt: new Date(),
            name: venueName,
            address: venueAddress,
            zip: venueZip,
            city: venueCity,
          });
          venuePath = newVenue.path;
        }

        const cleanUrls = urls.filter((url) => url.value !== '');

        const activityData = {
          organisation: firebase.firestore().doc(rootGetters['organization/get'].path),
          createdBy: firebase.firestore().doc(rootGetters['auth/get'].path),
          createdAt: new Date(),
          category: firebase.firestore().doc(category),
          subCategory: firebase.firestore().doc(subCategory),
          venue: firebase.firestore().doc(venuePath),
          urls: cleanUrls,
          ...rest,
        };

        const activity = await firebase.firestore().collection('activities').add(activityData);

        const imagesUpdateObj = [];

        const UPLOADERS = images.map((image) => {
          const formData = new FormData();
          formData.append('file', image.file);
          formData.append('tags', activity.id);
          formData.append('folder', process.env.VUE_APP_CLOUDINARY_FOLDER);
          formData.append('upload_preset', process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET);
          formData.append('api_key', process.env.VUE_APP_CLOUDINARY_API_KEY);

          return axios.post(`https://api.cloudinary.com/v1_1/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/image/upload`, formData, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
          }).then((response) => {
            const { data } = response;

            return firebase.firestore().collection('images').add({
              organisation: firebase.firestore().doc(rootGetters['organization/get'].path),
              activity: firebase.firestore().doc(activity.path),
              createdBy: firebase.firestore().doc(rootGetters['auth/get'].path),
              createdAt: new Date(),
              featured: image.featured,
              fileName: image.fileName,
              alternativeText: image.alternativeText,
              publicId: data.public_id,
            });
          });
        });

        await axios.all(UPLOADERS).then((res) => {
          res.forEach((doc) => {
            imagesUpdateObj.push(firebase.firestore().doc(doc.path));
          });
        });

        await firebase.firestore().collection('activities').doc(activity.id).update({
          images: imagesUpdateObj,
        });

        commit('loading/set', false, { root: true });
        commit('snackbar/show', { text: 'Veranstaltung erfolgreich erstellt' }, { root: true });
        return activity;
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
        return null;
      }
    },
    async update({ commit, getters, rootGetters }, payload) {
      commit('loading/set', true, { root: true });

      try {
        const {
          category, subCategory, venue, venueName, venueAddress, venueZip, venueCity, images, urls, ...rest
        } = payload;

        const imagesUpdateObj = [];

        const IMAGES_BATCH = firebase.firestore().batch();
        images.filter((image) => image.status !== 'deleted' && image.status !== 'added').map((image) => {
          const { id, path, ...imageData } = image;
          imagesUpdateObj.push(firebase.firestore().doc(path));
          return IMAGES_BATCH.update(firebase.firestore().doc(path), { ...imageData });
        });

        const DELETE_IMAGES = _.filter(images, (image) => image.status === 'deleted');
        DELETE_IMAGES.forEach((image) => IMAGES_BATCH.delete(firebase.firestore().doc(image.path)));

        await IMAGES_BATCH.commit();

        const UPLOAD_IMAGES = _.filter(images, (image) => image.status === 'added');
        // const UPLOAD_IMAGES_PROMISES = [];

        // UPLOAD_IMAGES.forEach((image) => {
        //   const uploadTask = firebase.storage().ref(`uploads/${getters.get.id}/${image.fileName}`).put(image.file).then(snapshot => firebase.firestore().collection('images').add({
        //     organisation: firebase.firestore().doc(rootGetters['organization/get'].path),
        //     activity: firebase.firestore().doc(getters.get.path),
        //     createdBy: firebase.firestore().doc(rootGetters['auth/get'].path),
        //     createdAt: new Date(),
        //     featured: image.featured,
        //     fileName: image.fileName,
        //     storagePath: snapshot.ref.fullPath,
        //     // storageDownloadURL: snapshot.ref.getDownloadURL(),
        //   }));
        //   UPLOAD_IMAGES_PROMISES.push(uploadTask);
        // });

        // await Promise.all(UPLOAD_IMAGES_PROMISES).then((snapshot) => {
        //   snapshot.forEach((doc) => {
        //     imagesUpdateObj.push(firebase.firestore().doc(doc.path));
        //   });
        // });

        const UPLOADERS = UPLOAD_IMAGES.map((image) => {
          const formData = new FormData();
          formData.append('file', image.file);
          formData.append('tags', getters.get.id);
          formData.append('folder', process.env.VUE_APP_CLOUDINARY_FOLDER);
          formData.append('upload_preset', process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET);
          formData.append('api_key', process.env.VUE_APP_CLOUDINARY_API_KEY);

          return axios.post(`https://api.cloudinary.com/v1_1/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/image/upload`, formData, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
          }).then((response) => {
            const { data } = response;

            return firebase.firestore().collection('images').add({
              organisation: firebase.firestore().doc(rootGetters['organization/get'].path),
              activity: firebase.firestore().doc(getters.get.path),
              createdBy: firebase.firestore().doc(rootGetters['auth/get'].path),
              createdAt: new Date(),
              featured: image.featured,
              fileName: image.fileName,
              alternativeText: image.alternativeText,
              publicId: data.public_id,
            });
          });
        });

        await axios.all(UPLOADERS).then((res) => {
          res.forEach((doc) => {
            imagesUpdateObj.push(firebase.firestore().doc(doc.path));
          });
        });

        let venuePath = venue;

        if (venue && venue === 'NEW') {
          const newVenue = await firebase.firestore().collection('venues').add({
            organisation: firebase.firestore().doc(rootGetters['organization/get'].path),
            createdBy: firebase.firestore().doc(rootGetters['auth/get'].path),
            createdAt: new Date(),
            name: venueName,
            address: venueAddress,
            zip: venueZip,
            city: venueCity,
          });
          venuePath = newVenue.path;
        }

        const cleanUrls = urls.filter((url) => url.value !== '');

        const activityData = {
          updatedBy: firebase.firestore().doc(rootGetters['auth/get'].path),
          updatedAt: new Date(),
          category: firebase.firestore().doc(category),
          subCategory: firebase.firestore().doc(subCategory),
          venue: firebase.firestore().doc(venuePath),
          images: imagesUpdateObj,
          urls: cleanUrls,
          ...rest,
        };

        const activity = await firebase.firestore().collection('activities').doc(getters.get.id).update(activityData);

        commit('loading/set', false, { root: true });
        commit('snackbar/show', { text: 'Veranstaltung erfolgreich bearbeitet' }, { root: true });
        return activity;
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
        return null;
      }
    },
    async updateOrganization({ commit, rootGetters }, payload) {
      commit('loading/set', true, { root: true });

      try {
        const { id, path, organization } = payload;

        const activity = await firebase.firestore().collection('activities').doc(id).update({
          updatedBy: firebase.firestore().doc(rootGetters['auth/get'].path),
          updatedAt: new Date(),
          organisation: firebase.firestore().doc(organization),
        });

        const images = await firebase.firestore().collection('images').where('activity', '==', firebase.firestore().doc(path)).get();
        if (!images.empty) {
          images.forEach(async (doc) => {
            await firebase.firestore().collection('images').doc(doc.ref.id).update({
              organisation: firebase.firestore().doc(organization),
            });
          });
        }

        commit('loading/set', false, { root: true });
        commit('snackbar/show', { text: 'Veranstaltung erfolgreich in Organisation verschoben' }, { root: true });
        return activity;
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
        return null;
      }
    },
    async highlight({ commit }, payload) {
      commit('loading/set', true, { root: true });

      try {
        await firebase.firestore().collection('activities').doc(payload.id).update({
          highlight: payload.highlight,
        });

        commit('loading/set', false, { root: true });
        commit('snackbar/show', { text: `Veranstaltung erfolgreich ${payload.highlight ? 'zu' : 'von'} den wow-wow Tipps ${payload.highlight ? 'hinzugefügt' : 'entfernt'}` }, { root: true });
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
      }
    },
    async status({ commit }, payload) {
      commit('loading/set', true, { root: true });

      try {
        await firebase.firestore().collection('activities').doc(payload.id).update({
          status: payload.status,
        });

        commit('loading/set', false, { root: true });
        if (payload.status === 'PUBLISHED') {
          commit('snackbar/show', { text: 'Veranstaltung ist nun sichtbar' }, { root: true });
        } else {
          commit('snackbar/show', { text: 'Veranstaltung ist nun nicht mehr sichtbar' }, { root: true });
        }
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
      }
    },
    async delete({ commit }, payload) {
      commit('loading/set', true, { root: true });

      try {
        await firebase.firestore().collection('activities').doc(payload.id).update({
          status: activityStatus.deleted.value,
        });

        commit('loading/set', false, { root: true });
        commit('dialog/delete', false);
        commit('snackbar/show', { text: 'Veranstaltung erfolgreich gelöscht' }, { root: true });
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
      }
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    get(state) {
      return state.activity;
    },
    field(state) {
      return getField(state.form);
    },
    all(state) {
      return state.activities;
    },
  },
};
