<template>
  <fragment>
    <v-card hover link ripple class="v-card-add" @click="openImage">
      <v-icon x-large>mdi-plus</v-icon>
    </v-card>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title>Bild hinzufügen</v-card-title>
        <v-card-text>
          <div
            :class="['dropzone-area', { 'dropzone-over': dragging }, { 'dropzone-has-error': error }]"
            @dragenter="dragging = true"
            @dragleave="dragging = false"
            drag-over="handleDragOver"
            v-if="!objectUrl"
          >
            <span class="dropzone-headline headline">Drop files here or click to upload.</span>
            <span class="dropzone-error" v-if="error">{{ error }}</span>
            <input type="file" @change="upload" accept="image/png, image/jpeg" />
          </div>
          <div v-else>
            <div class="image-container">
              <img class="image-preview" ref="source" :src="objectUrl" />
            </div>
            <div class="d-flex justify-center">
              <v-btn icon small @click="resetCropper">
                <v-icon small>mdi-aspect-ratio</v-icon>
              </v-btn>
              <div class="mx-2" />
              <v-btn icon small @click="rotateLeft">
                <v-icon small>mdi-rotate-left</v-icon>
              </v-btn>
              <v-btn icon small @click="rotateRight">
                <v-icon small>mdi-rotate-right</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">I accept</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </fragment>
</template>

<script>
import Cropper from 'cropperjs';

export default {
  data: () => ({
    dialog: false,
    dragging: false,
    error: null,
    cropper: null,
    objectUrl: null,
  }),

  methods: {
    openImage() {
      this.dialog = true;
    },
    upload(event) {
      const files = event.target.files || event.dataTransfer.files;
      if (files.length !== 1) {
        this.error = 'Es kann nur ein Bild hochgeladen werden.';
        return;
      }

      const file = event.target.files[0];
      // console.log(file.type);
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        this.error = 'Es können nur Bilder vom Typ JPG/PNG hochgeladen werden.';
        return;
      }

      if (this.cropper) {
        this.cropper.destroy();
      }

      if (this.objectUrl) {
        window.URL.revokeObjectURL(this.objectUrl);
      }

      this.objectUrl = window.URL.createObjectURL(file);
      this.$nextTick(this.setupCropperInstance);
    },

    setupCropperInstance() {
      // this.cropper = new Cropper(this.$refs.source, {
      //   aspectRatio: 1,
      //   dragMode: 'move',
      //   movable: false,
      //   scalable: false,
      //   zoomable: false,
      // });
      this.cropper = new Cropper(this.$refs.source, {
        dragMode: 'move',
        aspectRatio: 1,
        restore: false,
        guides: false,
        center: false,
        highlight: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
        toggleDragModeOnDblclick: false,
        viewMode: 1,
      });
    },

    resetCropper() {
      this.cropper.reset();
    },
    rotateLeft() {
      this.cropper.rotate(-90);
    },
    rotateRight() {
      this.cropper.rotate(90);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card-add {
  height: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone-area {
  height: 200px;
  position: relative;
  border: 2px dashed #cbcbcb;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.dropzone-over {
    border-style: solid;
  }

  &.dropzone-has-error {
    border-style: solid;
    border-color: #f00;
  }

  span {
    pointer-events: none;
    display: block;
  }

  .dropzone-error {
    color: #f00;
  }

  input {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.image-container {
  width: 100%;

  img {
    width: 100%;
  }
}
</style>

<style lang="scss">
@import "~cropperjs/dist/cropper.css";
</style>
