import firebase from 'firebase/app';
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash';
import dayjs from 'dayjs';
import dialog from './dialog';

const defaultFormData = {
  group: '',
  name: '',
  address: '',
  additional: '',
  zip: '',
  city: '',
  email: '',
  website: '',
  phone: '',
  description: '',
  category: '',
  subcategory: '',
  status: 'IN_PROGRESS',
  requestedDate: '',
  reminderDate: '',
  requestedReminderDate: '',
  assign: '',
};

export default {
  namespaced: true,
  modules: { dialog },
  state: {
    loading: false,
    contact: null,
    contacts: [],
    form: _.cloneDeep(defaultFormData),
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setAll(state, payload) {
      state.contacts = payload;
    },
    resetAll(state) {
      state.contacts = [];
    },
    set(state, payload) {
      state.contact = payload;
    },
    sync(state) {
      state.form = {
        group: state.contact.group,
        name: state.contact.name,
        address: state.contact.address,
        additional: state.contact.additional,
        zip: state.contact.zip,
        city: state.contact.city,
        email: state.contact.email,
        website: state.contact.website,
        phone: state.contact.phone,
        description: state.contact.description,
        category: state.contact.category,
        subcategory: state.contact.subcategory,
        status: state.contact.status,
        reminderDate: state.contact.reminderDate,
        assign: state.contact.assign,
      };
    },
    reset(state) {
      state.contact = null;
      state.form = _.cloneDeep(defaultFormData);
    },
    updateField(state, field) {
      updateField(state.form, field);
    },
  },
  actions: {
    async loadAll({ commit }) {
      commit('setLoading', true);
      return firebase
        .firestore()
        .collection('requests')
        .onSnapshot((snapshot) => {
          const data = [];
          snapshot.forEach((doc) => {
            const contact = { id: doc.id, path: doc.ref.path, ...doc.data() };
            if (contact.assign) {
              contact.assign.get().then((snap) => {
                contact.assign = { id: snap.id, path: snap.ref.path, ...snap.data() };
              });
            }
            data.push(contact);
          });
          commit('setAll', data);
          commit('setLoading', false);
        });
    },
    async loadAllByOrganisation({ commit }) {
      commit('setLoading', true);
      return firebase
        .firestore()
        .collection('requests')
        .onSnapshot((snapshot) => {
          const data = [];
          snapshot.forEach((doc) => {
            const contact = { id: doc.id, path: doc.ref.path, ...doc.data() };
            if (contact.assign) {
              contact.assign.get().then((snap) => {
                contact.assign = { id: snap.id, path: snap.ref.path, ...snap.data() };
              });
            }
            data.push(contact);
          });
          commit('setAll', data);
          commit('setLoading', false);
        });
    },
    async load({ commit }, payload) {
      try {
        const doc = await firebase
          .firestore()
          .collection('requests')
          .doc(payload)
          .get();

        const {
          category, subcategory, assign, reminderDate, ...data
        } = doc.data();

        const contactData = {
          id: doc.id,
          path: doc.ref.path,
          category: category ? category.path : null,
          subcategory: subcategory ? subcategory.path : null,
          assign: assign ? assign.path : null,
          ...data,
        };

        if (reminderDate) {
          contactData.reminderDate = dayjs(reminderDate.toDate()).format('YYYY-MM-DD');
        }

        commit('set', contactData);
      } catch (error) {
        commit('error/set', error, { root: true });
      }
    },
    async create({ commit, rootGetters }, payload) {
      commit('loading/set', true, { root: true });

      try {
        const contact = await firebase
          .firestore()
          .collection('requests')
          .where('email', '==', payload.email)
          .get();

        if (!contact.empty) {
          throw new Error('Ein Kontakt mit dieser E-Mail-Adresse existiert bereits.');
        }

        const {
          category, subcategory, assign, reminderDate, ...data
        } = payload;

        const contactData = {
          createdBy: firebase.firestore().doc(rootGetters['auth/get'].path),
          createdAt: new Date(),
          category: category ? firebase.firestore().doc(category) : null,
          subcategory: subcategory ? firebase.firestore().doc(subcategory) : null,
          assign: assign ? firebase.firestore().doc(assign) : null,
          complete: false,
          sendAutomaticMail: false,
          ...data,
        };

        if (reminderDate) {
          contactData.reminderDate = dayjs(reminderDate).toDate();
        }

        if (payload.name && payload.email && payload.group) {
          contactData.complete = true;
        }

        if (payload.group !== 'LOCATIONS' && payload.group !== 'ORGANIZER') {
          contactData.sendAutomaticMail = true;
        }

        const request = await firebase
          .firestore()
          .collection('requests')
          .add(contactData);

        commit('loading/set', false, { root: true });
        commit('dialog/toggleForm', false);
        commit('snackbar/show', { text: 'Kontakt erfolgreich erstellt' }, { root: true });
        return request;
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
      }

      return null;
    },
    async update({ commit, getters, rootGetters }, payload) {
      commit('loading/set', true, { root: true });

      try {
        const {
          category, subcategory, assign, reminderDate, ...data
        } = payload;

        const contactData = {
          updatedBy: firebase.firestore().doc(rootGetters['auth/get'].path),
          updatedAt: new Date(),
          category: category ? firebase.firestore().doc(category) : null,
          subcategory: subcategory ? firebase.firestore().doc(subcategory) : null,
          assign: assign ? firebase.firestore().doc(assign) : null,
          complete: false,
          sendAutomaticMail: false,
          ...data,
        };

        if (reminderDate) {
          contactData.reminderDate = dayjs(reminderDate).toDate();
        }

        if (payload.name && payload.email && payload.group) {
          contactData.complete = true;
        }

        if (payload.group !== 'LOCATIONS' && payload.group !== 'ORGANIZER') {
          contactData.sendAutomaticMail = true;
        }

        await firebase
          .firestore()
          .collection('requests')
          .doc(getters.get.id)
          .update(contactData);

        commit('loading/set', false, { root: true });
        commit('dialog/toggleForm', false);
        commit('snackbar/show', { text: 'Kontakt erfolgreich bearbeitet' }, { root: true });
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
      }
    },
    async claim({ commit }, payload) {
      commit('loading/set', true, { root: true });

      try {
        const { id, assign } = payload;
        await firebase
          .firestore()
          .collection('requests')
          .doc(id)
          .update({
            assign: assign ? firebase.firestore().doc(assign) : null,
          });

        commit('loading/set', false, { root: true });
        commit('dialog/toggleForm', false);
        commit('snackbar/show', { text: 'Kontakt erfolgreich bearbeitet' }, { root: true });
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
      }
    },
    async delete({ commit, getters }) {
      commit('loading/set', true, { root: true });

      try {
        await firebase
          .firestore()
          .collection('requests')
          .doc(getters.get.id)
          .delete();

        commit('loading/set', false, { root: true });
        commit('dialog/toggleDelete', false);
        commit('snackbar/show', { text: 'Kontakt erfolgreich gelöscht' }, { root: true });
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
      }
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    get(state) {
      return state.contact;
    },
    field(state) {
      return getField(state.form);
    },
    all(state) {
      return state.contacts;
    },
  },
};
