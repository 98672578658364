export default {
  namespaced: true,
  state: {
    error: null,
  },
  mutations: {
    set(state, payload) {
      state.error = payload;
    },
    clear(state) {
      state.error = null;
    },
  },
  getters: {
    get(state) {
      return state.error;
    },
  },
};
