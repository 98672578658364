import firebase from 'firebase/app';
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash';
import dialog from './dialog';

const defaultFormData = {
  title: '',
  slug: null,
  author: {
    name: '',
    about: '',
    website: '',
    image: {
      url: '',
    },
  },
  date: '',
  header: {
    image: {
      url: '',
      caption: '',
    },
  },
  content: null,
  public: false,
};

export default {
  namespaced: true,
  modules: { dialog },
  state: {
    listenerUnsubscribe: [],
    loading: false,
    blog: null,
    blogs: [],
    form: _.cloneDeep(defaultFormData),
  },
  mutations: {
    SET_LISTENER_UNSUBSCRIBE(state, payload) {
      state.listenerUnsubscribe.push(payload);
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setAll(state, payload) {
      state.blogs = payload;
    },
    resetAll(state) {
      state.blogs = [];
      state.listenerUnsubscribe.forEach((store) => {
        store();
      });
      state.listenerUnsubscribe = [];
    },
    set(state, payload) {
      state.blog = payload;
    },
    sync(state) {
      state.form = _.cloneDeep(state.blog);
    },
    reset(state) {
      state.blog = null;
      state.form = _.cloneDeep(defaultFormData);
    },
    updateField(state, field) {
      updateField(state.form, field);
    },
  },
  actions: {
    async loadAll({ commit }) {
      commit('setLoading', true);
      const users = {};
      const usersData = await firebase.firestore().collection('users').get();
      usersData.forEach((doc) => {
        users[doc.id] = { id: doc.id, path: doc.ref.path, ...doc.data() };
      });

      const unsubscribe = firebase.firestore().collection('blogs').orderBy('title').onSnapshot((snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          const blog = {
            _type: 'Blog', id: doc.id, path: doc.ref.path, ...doc.data(),
          };
          if (blog.createdBy) {
            blog.createdBy = users[blog.createdBy.id];
          }
          if (blog.updatedBy) {
            blog.updatedBy = users[blog.updatedBy.id];
          }
          data.push(blog);
        });
        commit('setAll', data);
        commit('setLoading', false);
      });
      commit('SET_LISTENER_UNSUBSCRIBE', unsubscribe);
    },
    async load({ commit }, payload) {
      try {
        const doc = await firebase.firestore().collection('blogs').doc(payload).get();
        const { date, ...data } = doc.data();

        const blog = {
          _type: 'Blog', id: doc.id, path: doc.ref.path, ...data,
        };

        if (date) {
          blog.date = date.toDate();
        }

        commit('set', blog);
        return blog;
      } catch (error) {
        commit('error/set', error, { root: true });
      }
      return null;
    },
    async create({ commit, rootGetters }, payload) {
      commit('loading/set', true, { root: true });

      try {
        const exist = await firebase.firestore().collection('blogs').where('slug', '==', payload.slug).get();
        if (!exist.empty) {
          throw new Error('Es existiert bereits ein Blog mit einem solchen URL Pfadsegment');
        }

        const blog = await firebase.firestore().collection('blogs').add({
          createdBy: firebase.firestore().doc(rootGetters['auth/get'].path),
          createdAt: new Date(),
          ...payload,
        });

        commit('loading/set', false, { root: true });
        commit('dialog/form', false);
        commit('snackbar/show', { text: 'Blog erfolgreich erstellt' }, { root: true });
        return blog;
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
        return null;
      }
    },
    async update({ commit, getters, rootGetters }, payload) {
      commit('loading/set', true, { root: true });

      try {
        if (payload.slug) {
          // eslint-disable-next-line newline-per-chained-call
          const exist = await firebase.firestore().collection('blogs').where('slug', '==', payload.slug).limit(1).get();
          if (!exist.empty && getters.get.id !== exist.docs[0].id) {
            throw new Error('Es existiert bereits ein Blog mit einem solchen URL Pfadsegment');
          }
        }

        await firebase.firestore().collection('blogs').doc(getters.get.id).update({
          updatedBy: firebase.firestore().doc(rootGetters['auth/get'].path),
          updatedAt: new Date(),
          public: false,
          ...payload,
        });

        commit('set', { ...getters.get, ...payload });
        commit('loading/set', false, { root: true });
        commit('dialog/form', false);
        commit('snackbar/show', { text: 'Blog erfolgreich bearbeitet' }, { root: true });
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
      }
    },
    async status({ commit }, payload) {
      commit('loading/set', true, { root: true });

      try {
        await firebase.firestore().collection('blogs').doc(payload.id).update({
          public: payload.public,
        });

        commit('loading/set', false, { root: true });
        if (payload.public) {
          commit('snackbar/show', { text: 'Blogbeitrag ist nun ab dem Veröffentlichungsdatum sichtbar' }, { root: true });
        } else {
          commit('snackbar/show', { text: 'Blogbeitrag ist nun nicht mehr sichtbar' }, { root: true });
        }
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
      }
    },
    async delete({ commit }, payload) {
      commit('loading/set', true, { root: true });

      try {
        await firebase.firestore().collection('blogs').doc(payload.id).delete();

        commit('loading/set', false, { root: true });
        commit('dialog/delete', false);
        commit('snackbar/show', { text: 'Blog erfolgreich gelöscht' }, { root: true });
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
      }
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    get(state) {
      return state.blog;
    },
    field(state) {
      return getField(state.form);
    },
    all(state) {
      return state.blogs;
    },
  },
};
