<template>
  <Layout />
</template>

<script>
export default {
  name: 'App',
  components: {
    Layout: () => import(/* webpackChunkName: "core" */ '@/components/layout/Layout.vue'),
  },
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";

.theme--light.v-data-table tbody tr.v-data-table__empty-wrapper:hover {
  background: none;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:last-child {
    height: auto;
  }
}
</style>
