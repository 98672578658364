<template>
  <v-alert :type="type" :dismissible="dismissible" :class="{ 'v-alert-in-card': isParentCard, 'v-alert-in-stepper': isParentStepper }">
    <slot></slot>
  </v-alert>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    dismissible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'error',
      validator(val) {
        return [
          'info',
          'error',
          'success',
          'warning',
        ].includes(val);
      },
    },
  },

  computed: {
    isParentCard() {
      return this.$parent.constructor.options.name === 'v-card';
    },
    isParentStepper() {
      return this.$parent.constructor.options.name === 'v-stepper';
    },
  },

  methods: {

    destroy() {
      this.$store.commit('error/clear');
    },
  },

  destroyed() {
    this.destroy();
  },
};
</script>

<style lang="scss" scoped>
.v-alert-in-card {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  margin-bottom: 0 !important;
}
.v-alert-in-stepper {
  border-radius: 0 !important;
  margin-bottom: 0 !important;
}
</style>
