<template>
  <span>
    <template v-if="$vuetify.breakpoint.xs">
      <v-btn text small :to="to" @click="$emit('click')">
        <v-icon small left v-if="icon">{{ icon }}</v-icon>
        <slot></slot>
      </v-btn>
    </template>
    <template v-else>
      <template v-if="Boolean(tooltip)">
        <v-tooltip bottom open-delay="500">
          <template v-slot:activator="{ on }">
            <v-btn text small :icon="Boolean(icon)" :to="to" @click="$emit('click')" v-on="on">
              <v-icon small v-if="icon">{{ icon }}</v-icon>
              <slot v-if="!Boolean(icon)"></slot>
            </v-btn>
          </template>
          <span>{{ tooltip }}</span>
        </v-tooltip>
      </template>
      <template v-else>
        <v-btn text small :icon="Boolean(icon)" :to="to" @click="$emit('click')">
          <v-icon small v-if="icon">{{ icon }}</v-icon>
          <slot v-if="!Boolean(icon)"></slot>
        </v-btn>
      </template>
    </template>
  </span>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
    },
    icon: {
      type: String,
    },
    tooltip: {
      type: String,
    },
  },
};
</script>
