export default {
  namespaced: true,
  state: {
    form: false,
    import: false,
    delete: false,
    status: false,
  },
  mutations: {
    toggleForm(state, payload) {
      state.form = payload;
    },
    toggleImport(state, payload) {
      state.import = payload;
    },
    toggleDelete(state, payload) {
      state.delete = payload;
    },
    toggleStatus(state, payload) {
      state.status = payload;
    },
  },
  getters: {
    form(state) {
      return state.form;
    },
    import(state) {
      return state.import;
    },
    delete(state) {
      return state.delete;
    },
    status(state) {
      return state.status;
    },
  },
};
