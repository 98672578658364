/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash';
import dialog from './dialog';
import { geocode } from '../../helpers/geocode';

const defaultFormData = {
  name: '',
  address: '',
  additional: '',
  zip: '',
  city: '',
  website: '',
  phone: '',
};

export default {
  namespaced: true,
  modules: { dialog },
  state: {
    listenerUnsubscribe: [],
    loading: false,
    organization: null,
    organizations: [],
    form: _.cloneDeep(defaultFormData),
  },
  mutations: {
    SET_LISTENER_UNSUBSCRIBE(state, payload) {
      state.listenerUnsubscribe.push(payload);
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setAll(state, payload) {
      state.organizations = payload;
    },
    resetAll(state) {
      state.organizations = [];
      state.listenerUnsubscribe.forEach((store) => {
        store();
      });
      state.listenerUnsubscribe = [];
    },
    set(state, payload) {
      state.organization = payload;
    },
    unset(state) {
      state.organization = null;
    },
    sync(state) {
      state.form = {
        name: state.organization.name || '',
        address: state.organization.address || '',
        additional: state.organization.additional || '',
        zip: state.organization.zip || '',
        city: state.organization.city || '',
        website: state.organization.website || '',
        phone: state.organization.phone || '',
      };
    },
    reset(state) {
      state.form = _.cloneDeep(defaultFormData);
    },
    updateField(state, field) {
      updateField(state.form, field);
    },
  },
  actions: {
    loadAll({ commit }) {
      commit('setLoading', true);
      const unsubscribe = firebase.firestore().collection('organisations').orderBy('name').onSnapshot((snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          const organization = {
            _type: 'Organization', id: doc.id, path: doc.ref.path, ...doc.data(),
          };
          data.push(organization);
        });
        commit('setAll', data);
        commit('setLoading', false);
      });
      commit('SET_LISTENER_UNSUBSCRIBE', unsubscribe);
    },
    loadAllMain({ commit }) {
      commit('setLoading', true);
      const unsubscribe = firebase.firestore().collection('organisations').where('organisation', '==', null).onSnapshot((snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          const organization = {
            _type: 'Organization', id: doc.id, path: doc.ref.path, ...doc.data(),
          };
          data.push(organization);
        });
        commit('setAll', data);
        commit('setLoading', false);
      });
      commit('SET_LISTENER_UNSUBSCRIBE', unsubscribe);
    },
    loadAllByOrganisation({ commit, getters }) {
      commit('setLoading', true);
      const unsubscribe = firebase.firestore().collection('organisations').where('organisation', '==', firebase.firestore().doc(getters.get.path)).onSnapshot((snapshot) => {
        const data = [];
        snapshot.forEach(async (doc) => {
          const organization = {
            _type: 'Organization', id: doc.id, path: doc.ref.path, ...doc.data(),
          };
          data.push(organization);
        });
        commit('setAll', data);
        commit('setLoading', false);
      });
      commit('SET_LISTENER_UNSUBSCRIBE', unsubscribe);
    },
    async load({ commit }, payload) {
      try {
        const doc = await firebase.firestore().collection('organisations').doc(payload).get();
        commit('set', {
          _type: 'Organization', id: doc.id, path: doc.ref.path, ...doc.data(),
        });
      } catch (error) {
        commit('error/set', error, { root: true });
      }
    },
    async create({ commit, getters, rootGetters }, payload) {
      commit('loading/set', true, { root: true });

      try {
        const organization = await firebase.firestore().collection('organisations').add({
          createdBy: firebase.firestore().doc(rootGetters['auth/get'].path),
          createdAt: new Date(),
          organisation: getters.get ? firebase.firestore().doc(getters.get.path) : null,
          ...payload,
        });

        const geolocation = await geocode(payload.address, payload.zip, payload.city);
        await firebase.firestore().collection('venues').add({
          createdBy: firebase.firestore().doc(rootGetters['auth/get'].path),
          createdAt: new Date(),
          organisation: firebase.firestore().doc(organization.path),
          name: payload.name,
          address: payload.address,
          zip: payload.zip,
          city: payload.city,
          defaultVenue: true,
          geolocation,
        });

        commit('loading/set', false, { root: true });
        commit('dialog/create', false);
        commit('snackbar/show', { text: 'Organisation erfolgreich erstellt' }, { root: true });
        return organization;
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
      }

      return null;
    },
    async update({ commit, getters, rootGetters }, payload) {
      commit('loading/set', true, { root: true });

      try {
        await firebase.firestore().collection('organisations').doc(getters.get.id).update({
          updatedBy: firebase.firestore().doc(rootGetters['auth/get'].path),
          updatedAt: new Date(),
          ...payload,
        });

        const venues = await firebase.firestore().collection('venues')
          .where('organisation', '==', firebase.firestore().doc(getters.get.path))
          .where('defaultVenue', '==', true)
          .get();

        if (!venues.empty) {
          venues.forEach(async (doc) => {
            const { address, zip, city } = doc.data();

            const venue = {
              name: payload.name,
              address: payload.address,
              zip: payload.zip,
              city: payload.city,
              updatedBy: firebase.firestore().doc(rootGetters['auth/get'].path),
              updatedAt: new Date(),
            };

            if (address !== payload.address || zip !== payload.zip || city !== payload.city) {
              venue.geolocation = await geocode(payload.address, payload.zip, payload.city);
            }

            await firebase.firestore().collection('venues').doc(doc.ref.id).update(venue);
          });
        }

        commit('set', { ...getters.get, ...payload });
        commit('loading/set', false, { root: true });
        commit('dialog/update', false);
        commit('snackbar/show', { text: 'Organisation erfolgreich bearbeitet' }, { root: true });
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
      }
    },
    async delete({ commit }, payload) {
      commit('loading/set', true, { root: true });

      try {
        await firebase.firestore().collection('organisations').doc(payload.id).delete();

        commit('loading/set', false, { root: true });
        commit('dialog/delete', false);
        commit('snackbar/show', { text: 'Organisation erfolgreich gelöscht' }, { root: true });
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
      }
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    get(state) {
      return state.organization;
    },
    field(state) {
      return getField(state.form);
    },
    all(state) {
      return state.organizations;
    },
  },
};
