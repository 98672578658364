import {
  required, email, confirmed, max, min, min_value as minValue, numeric,
} from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import PhoneNumber from 'awesome-phonenumber';
import isURL from 'validator/lib/isURL';
// import dayjs from 'dayjs';

// https://github.com/logaretm/vee-validate/blob/2.2.15/locale/de.js
extend('required', {
  ...required,
  message: (field) => `${field} ist ein Pflichtfeld`,
});

extend('email', {
  ...email,
  message: (field) => `${field} muss eine gültige E-Mail-Adresse sein`,
});

extend('url', {
  validate: (value) => isURL(value),
  message: (field) => `${field} ist keine gültige URL`,
});

extend('confirmed', {
  ...confirmed,
  message: (field, { target }) => `Das Feld ${field} stimmt nicht mit ${target} überein`,
});

extend('max', {
  ...max,
  message: (field, { length }) => `${field} darf nicht länger als ${length} Zeichen sein`,
});

extend('min', {
  ...min,
  message: (field, { length }) => `${field} muss mindestens ${length} Zeichen lang sein`,
});

extend('min_value', {
  ...minValue,
  message: (field, data) => `${field} muss mindestens ${data.min} sein`,
});

extend('numeric', {
  ...numeric,
  message: (field) => `${field} darf nur numerische Zeichen enthalten`,
});

extend('phone', {
  params: ['country'],
  validate: (value, { country = 'CH' }) => {
    const pn = new PhoneNumber(value, country);
    return pn.isValid();
  },
  message: (field) => `${field} ist keine gültige Telefon-Nummer`,
});

// https://stackoverflow.com/questions/57775092/how-can-i-validate-date-after-date-before-using-veevalidate-v3-and-vuejs-datep
// extend('time_is_after', {
//   params: ['min', 'included'],
//   validate: (value, { min, included }) => {
//     const date = new Date();

//     // const minDate = dayjs(date.setTime(min));
//     const minDate = date.setTime(min);
//     const valueDate = dayjs(value);
//     console.log({ minDate, valueDate });
//     return included ? valueDate.isSameOrBefore(minDate) : valueDate.isBefore(minDate);
//   },
//   message: (field, placeholders) => {
//     const min = dayjs(placeholders.min).format('HH:mm');
//     return `The ${field} field must come after ${min}`;
//   },
//   // message: (field, { length }) => `${field} darf nicht länger als ${length} Zeichen sein`,
// });
