import Vue from 'vue';
import Vuex from 'vuex';
import { Ability } from '@casl/ability';

import abilityPlugin from './ability';
import navigation from './navigation';
import error from './error';
import loading from './loading';
import snackbar from './snackbar';
import auth from './auth';
import activity from './activity/index';
import organization from './organization/index';
import user from './user/index';
import category from './category/index';
import request from './request/index';
import venue from './venue/index';
import blog from './blog/index';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    abilityPlugin,
  ],
  getters: {
    ability() {
      return new Ability([], {
        subjectName(subject) {
          // eslint-disable-next-line no-underscore-dangle
          return !subject || typeof subject === 'string' ? subject : subject._type;
        },
      });
    },
  },
  modules: {
    navigation,
    error,
    loading,
    snackbar,
    auth,
    activity,
    organization,
    user,
    category,
    request,
    venue,
    blog,
  },
});
