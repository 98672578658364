export default {
  published: {
    text: 'published',
    value: 'PUBLISHED',
  },
  deleted: {
    text: 'deleted',
    value: 'DELETED',
  },
};

export const activity = {
  draft: {
    text: 'draft',
    value: 'DRAFT',
  },
  review: {
    text: 'review',
    value: 'REVIEW',
  },
  published: {
    text: 'published',
    value: 'PUBLISHED',
  },
  deleted: {
    text: 'deleted',
    value: 'DELETED',
  },
};

export const request = [
  {
    text: 'in progress',
    description: 'Die Daten sind noch nicht vollständig erfasst worden',
    value: 'IN_PROGRESS',
    color: 'orange',
  },
  {
    text: 'ready to request',
    description: 'Daten sind vollständig und können versendet werden',
    value: 'READY_TO_REQUEST',
    color: 'blue',
  },
  {
    text: 'requested',
    description: 'Anfrage wurde versendet',
    value: 'REQUESTED',
    color: 'grey',
  },
  {
    text: 'reminder',
    description: 'Anfrage wurde versendet aber nach 10 Tagen noch nicht beantwortet',
    value: 'REMINDER',
    color: 'blue',
  },
  {
    text: 'requested reminder',
    description: 'Erinnerung wurde versendet',
    value: 'REQUESTED_REMINDER',
    color: 'grey',
  },
  {
    text: 'not responded',
    description: 'Anfrage wurde nach 10 Tagen nach der Erinnerung noch nicht beantwortet',
    value: 'NOT_RESPONDED',
    color: 'red',
  },
  {
    text: 'done',
    description: 'Veranstaltung wurde erstellt',
    value: 'DONE',
    color: 'green',
  },
  {
    text: 'rejected',
    description: 'Veranstalter wünscht sich keinen Eintrag',
    value: 'REJECTED',
    color: 'red',
  },
];
