export default {
  namespaced: true,
  state: {
    snackbar: {
      visible: false,
      text: null,
      timeout: 6000,
      multiline: false,
      color: 'success',
    },
  },
  mutations: {
    show(state, payload) {
      state.snackbar.text = payload.text;
      state.snackbar.multiline = payload.text.length > 50;

      if (payload.multiline) {
        state.snackbar.multiline = payload.multiline;
      }
      if (payload.timeout) {
        state.snackbar.timeout = payload.timeout;
      }
      if (payload.color) {
        state.snackbar.color = payload.color;
      }

      state.snackbar.visible = true;
    },
    close(state) {
      state.snackbar.visible = false;
      state.snackbar.multiline = false;
      state.snackbar.timeout = 6000;
      state.snackbar.text = null;
      state.snackbar.color = 'success';
    },
  },
  getters: {
    get(state) {
      return state.snackbar;
    },
  },
};
