export default {
  namespaced: true,
  state: {
    form: false,
    subcategory: false,
    delete: false,
    defaultActivityType: false,
  },
  mutations: {
    toggleForm(state, payload) {
      state.form = payload;
    },
    toggleSubcategory(state, payload) {
      state.subcategory = payload;
    },
    toggleDelete(state, payload) {
      state.delete = payload;
    },
    toggleDefaultActivityType(state, payload) {
      state.defaultActivityType = payload;
    },
  },
  getters: {
    form(state) {
      return state.form;
    },
    subcategory(state) {
      return state.subcategory;
    },
    delete(state) {
      return state.delete;
    },
    defaultActivityType(state) {
      return state.defaultActivityType;
    },
  },
};
