import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';

export default Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    region: 'CH',
    language: 'de',
  },
});
