import Vue from 'vue';
import Router from 'vue-router';
import firebase from 'firebase/app';
import 'firebase/auth';

Vue.use(Router);

const organizationChilds = (prefix = null) => ([
  {
    path: '',
    name: `route.admin.${prefix ? `${prefix}-` : ''}organization`,
    component: () => import(/* webpackChunkName: "organization-detail" */ '@/views/organization/Activities.vue'),
  },
  {
    path: 'settings',
    name: `route.admin.${prefix ? `${prefix}-` : ''}organization.settings`,
    component: () => import(/* webpackChunkName: "organization-detail" */ '@/views/organization/Settings.vue'),
  },
  {
    path: 'activity/create',
    name: `route.admin.${prefix ? `${prefix}-` : ''}organization.activity.create`,
    component: () => import(/* webpackChunkName: "organization-detail" */ '@/views/organization/ActivitiesForm.vue'),
  },
  {
    path: 'activity/:activityID/edit',
    name: `route.admin.${prefix ? `${prefix}-` : ''}organization.activity.edit`,
    component: () => import(/* webpackChunkName: "organization-detail" */ '@/views/organization/ActivitiesForm.vue'),
  },
]);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'route.home',
      meta: {
        requiresAuth: true,
      },
      component: () => import(/* webpackChunkName: "organization" */ '@/views/Home.vue'),
    },
    {
      path: '/activity',
      meta: {
        requiresAuth: true,
      },
      component: () => import(/* webpackChunkName: "organization-detail" */ '@/views/Activitiy.vue'),
      children: [
        {
          path: '',
          name: 'route.activity',
          component: () => import(/* webpackChunkName: "error" */ '@/views/Error.vue'),
        },
        {
          path: 'create',
          name: 'route.activity.create',
          component: () => import(/* webpackChunkName: "organization-detail" */ '@/views/organization/ActivitiesForm.vue'),
        },
        {
          path: ':activityID/edit',
          name: 'route.activity.edit',
          component: () => import(/* webpackChunkName: "organization-detail" */ '@/views/organization/ActivitiesForm.vue'),
        },
        {
          path: '*',
          name: 'route.activity.error',
          component: () => import(/* webpackChunkName: "error" */ '@/views/Error.vue'),
        },
      ],
    },
    {
      path: '/settings',
      name: 'route.organization.settings',
      component: () => import(/* webpackChunkName: "organization-detail" */ '@/views/organization/Settings.vue'),
    },
    {
      path: '/organization/:organizationID',
      meta: {
        requiresAuth: true,
      },
      component: () => import(/* webpackChunkName: "organization-detail" */ '@/views/organization/Organization.vue'),
      children: [
        ...organizationChilds(),
        {
          path: 'organization/:subOrganizationID',
          component: () => import(/* webpackChunkName: "organization-detail" */ '@/views/organization/Organization.vue'),
          children: [
            ...organizationChilds('sub'),
          ],
        },
        {
          path: '*',
          name: 'route.admin.organization.error',
          component: () => import(/* webpackChunkName: "error" */ '@/views/Error.vue'),
        },
      ],
    },
    {
      path: '/activities',
      meta: {
        requiresAuth: true,
      },
      component: () => import(/* webpackChunkName: "organization-detail" */ '@/views/Activities.vue'),
    },
    {
      path: '/category',
      name: 'route.category',
      meta: {
        requiresAuth: true,
      },
      component: () => import(/* webpackChunkName: "category" */ '@/views/Category.vue'),
    },
    {
      path: '/requests',
      name: 'route.requests',
      meta: {
        requiresAuth: true,
      },
      component: () => import(/* webpackChunkName: "request" */ '@/views/Request.vue'),
    },
    {
      path: '/blogs',
      name: 'route.blog',
      meta: {
        requiresAuth: true,
      },
      component: () => import(/* webpackChunkName: "blog" */ '@/views/Blog.vue'),
    },
    {
      path: '/blogs/:blogID',
      name: 'route.blog.detail',
      component: () => import(/* webpackChunkName: "blog" */ '@/views/blog/Detail.vue'),
    },
    {
      path: '/users',
      name: 'route.users',
      meta: {
        requiresAuth: true,
      },
      component: () => import(/* webpackChunkName: "category" */ '@/views/Users.vue'),
    },
    {
      path: '/auth/login',
      name: 'route.signin',
      meta: {
        requiresNotAuth: true,
      },
      component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Signin.vue'),
    },
    {
      path: '/auth/register',
      name: 'route.signup',
      meta: {
        requiresNotAuth: true,
      },
      component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Signup.vue'),
    },
    {
      path: '/auth/forgot',
      name: 'route.forgot',
      meta: {
        requiresNotAuth: true,
      },
      component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Forgot.vue'),
    },
    {
      path: '/auth/action',
      name: 'route.action',
      meta: {
        requiresNotAuth: true,
      },
      component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Action.vue'),
    },
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "core" */ '@/views/PageNotFound.vue'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresNotAuth = to.matched.some((record) => record.meta.requiresNotAuth);
  const isAuthenticated = firebase.auth().currentUser;

  if (requiresAuth && !isAuthenticated) {
    next('/auth/login');
  } else if (requiresNotAuth && isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

export default router;
