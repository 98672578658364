<template>
  <v-card hover link ripple class="v-card-add" :to="to" @click="$emit('click')">
    <v-icon x-large>mdi-plus</v-icon>
  </v-card>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card-add {
  height: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
