import firebase from 'firebase/app';
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash';
import dialog from './dialog';

const defaultFormData = {
  name: {
    de: '',
  },
  defaultActivityType: [],
};

export default {
  namespaced: true,
  state: {
    listenerUnsubscribe: [],
    loading: true,
    category: null,
    categories: [],
    form: _.cloneDeep(defaultFormData),
  },
  mutations: {
    SET_LISTENER_UNSUBSCRIBE(state, payload) {
      state.listenerUnsubscribe.push(payload);
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setAll(state, payload) {
      state.categories = payload;
    },
    resetAll(state) {
      state.categories = [];
      state.listenerUnsubscribe.forEach((store) => {
        store();
      });
      state.listenerUnsubscribe = [];
    },
    set(state, payload) {
      state.category = payload;
    },
    sync(state, payload) {
      state.form = _.cloneDeep(payload);
    },
    reset(state) {
      state.category = null;
    },
    resetForm(state) {
      state.form = _.cloneDeep(defaultFormData);
    },
    updateField(state, field) {
      updateField(state.form, field);
    },
  },
  actions: {
    loadAll({ commit }) {
      commit('setLoading', true);
      const unsubscribe = firebase.firestore().collection('categories').orderBy('sorting').onSnapshot((snapshot) => {
        const categoriesArr = [];
        snapshot.forEach((doc) => {
          categoriesArr.push({ id: doc.id, path: doc.ref.path, ...doc.data() });
        });
        commit('setAll', categoriesArr);
        commit('setLoading', false);
      });
      commit('SET_LISTENER_UNSUBSCRIBE', unsubscribe);
    },
    async load({ commit }, payload) {
      try {
        const doc = await firebase
          .firestore()
          .collection('categories')
          .doc(payload)
          .get();

        const data = doc.data();
        commit('set', { id: doc.id, path: doc.ref.path, ...data });
      } catch (error) {
        commit('error/set', error, { root: true });
      }
    },
    async create({ commit, rootGetters }, payload) {
      commit('loading/set', true, { root: true });

      try {
        let sortIndex = 10;
        const querySnapshot = await firebase
          .firestore()
          .collection('categories')
          .orderBy('sorting', 'desc')
          .limit(1)
          .get();

        if (!querySnapshot.empty) {
          sortIndex = querySnapshot.docs[0].data().sorting + 10;
        }

        const { defaultActivityType = [], ...data } = payload;

        const queryDefaultActivityType = await firebase
          .firestore()
          .collection('categories')
          .where('defaultActivityType', 'array-contains-any', defaultActivityType)
          .get();
        if (!queryDefaultActivityType.empty) {
          commit('dialog/toggleDefaultActivityType', true);
          throw new Error('DEFAULTACTIVITYTYPE_EXISTS');
        }

        const category = await firebase
          .firestore()
          .collection('categories')
          .add({
            createdBy: firebase.firestore().doc(rootGetters['auth/get'].path),
            createdAt: new Date(),
            defaultActivityType,
            ...data,
            sorting: sortIndex,
          });
        commit('loading/set', false, { root: true });
        commit('dialog/toggleForm', false);
        commit('snackbar/show', { text: 'Kategorie erfolgreich erstellt' }, { root: true });
        return category;
      } catch (error) {
        commit('loading/set', false, { root: true });
        if (error.message !== 'DEFAULTACTIVITYTYPE_EXISTS') {
          commit('error/set', error, { root: true });
        }
      }
      return null;
    },
    async update({ commit, getters, rootGetters }, payload) {
      commit('loading/set', true, { root: true });
      try {
        const { defaultActivityType = [], ...data } = payload;
        await firebase
          .firestore()
          .collection('categories')
          .doc(getters.get.id)
          .update({
            updatedBy: firebase.firestore().doc(rootGetters['auth/get'].path),
            updatedAt: new Date(),
            defaultActivityType,
            ...data,
          });
        commit('loading/set', false, { root: true });
        commit('dialog/toggleForm', false);
        commit('snackbar/show', { text: 'Kategorie erfolgreich bearbeitet' }, { root: true });
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
      }
    },
    async updateSorting({ commit }, payload) {
      try {
        const batch = firebase.firestore().batch();

        await Promise.all(
          payload.map(async (id, index) => {
            const category = await firebase
              .firestore()
              .collection('categories')
              .doc(id);
            batch.update(category, { sorting: (index + 1) * 10 });
          }),
        );
        await batch.commit();
        commit('snackbar/show', { text: 'Kategorien erfolgreich sortiert' }, { root: true });
      } catch (error) {
        commit('error/set', error, { root: true });
      }
    },
    async delete({ commit, getters }) {
      commit('loading/set', true, { root: true });

      try {
        const batch = firebase.firestore().batch();

        const category = await firebase
          .firestore()
          .collection('categories')
          .doc(getters.get.id);

        await category
          .collection('categories')
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              batch.delete(doc.ref);
            });
          });

        batch.delete(category);
        await batch.commit();

        commit('loading/set', false, { root: true });
        commit('dialog/toggleDelete', false);
        commit('snackbar/show', { text: 'Kategorie erfolgreich gelöscht' }, { root: true });
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
      }
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    get(state) {
      return state.category;
    },
    field(state) {
      return getField(state.form);
    },
    all(state) {
      return state.categories;
    },
  },
  modules: {
    dialog,
    subcategory: {
      namespaced: true,
      state: {
        listenerUnsubscribe: [],
        loading: false,
        category: null,
        categories: [],
      },
      mutations: {
        SET_LISTENER_UNSUBSCRIBE(state, payload) {
          state.listenerUnsubscribe.push(payload);
        },
        setLoading(state, payload) {
          state.loading = payload;
        },
        setAll(state, payload) {
          state.categories = payload;
        },
        resetAll(state) {
          state.categories = [];
          state.listenerUnsubscribe.forEach((store) => {
            store();
          });
          state.listenerUnsubscribe = [];
        },
        set(state, payload) {
          state.category = payload;
        },
        reset(state) {
          state.category = null;
        },
      },
      actions: {
        loadAll({ commit, rootGetters }) {
          commit('setLoading', true);
          const unsubscribe = firebase.firestore().collection('categories').doc(rootGetters['category/get'].id).collection('categories')
            .orderBy('sorting')
            .onSnapshot((snapshot) => {
              const categoriesArr = [];
              snapshot.forEach((doc) => {
                categoriesArr.push({ id: doc.id, path: doc.ref.path, ...doc.data() });
              });
              commit('setAll', categoriesArr);
              commit('setLoading', false);
            });
          commit('SET_LISTENER_UNSUBSCRIBE', unsubscribe);
        },
        loadAllByCategoryPath({ commit }, payload) {
          commit('setLoading', true);
          const unsubscribe = firebase.firestore().doc(payload).collection('categories').orderBy('sorting')
            .onSnapshot((snapshot) => {
              const categoriesArr = [];
              snapshot.forEach((doc) => {
                categoriesArr.push({ id: doc.id, path: doc.ref.path, ...doc.data() });
              });
              commit('setAll', categoriesArr);
              commit('setLoading', false);
            });
          commit('SET_LISTENER_UNSUBSCRIBE', unsubscribe);
        },
        async load({ commit, rootGetters }, payload) {
          try {
            const doc = await firebase
              .firestore()
              .collection('categories')
              .doc(rootGetters['category/get'].id)
              .collection('categories')
              .doc(payload)
              .get();
            const data = doc.data();
            commit('set', { id: doc.id, path: doc.ref.path, ...data });
          } catch (error) {
            commit('error/set', error, { root: true });
          }
        },
        async create({ commit, rootGetters }, payload) {
          commit('loading/set', true, { root: true });

          try {
            let sortIndex = 10;
            const querySnapshot = await firebase
              .firestore()
              .collection('categories')
              .doc(rootGetters['category/get'].id)
              .collection('categories')
              .orderBy('sorting', 'desc')
              .limit(1)
              .get();

            if (!querySnapshot.empty) {
              sortIndex = querySnapshot.docs[0].data().sorting + 10;
            }

            const category = await firebase
              .firestore()
              .collection('categories')
              .doc(rootGetters['category/get'].id)
              .collection('categories')
              .add({
                createdBy: firebase.firestore().doc(rootGetters['auth/get'].path),
                createdAt: new Date(),
                ...payload,
                sorting: sortIndex,
              });
            commit('loading/set', false, { root: true });
            commit('category/dialog/toggleForm', false, { root: true });
            commit('snackbar/show', { text: 'Kategorie erfolgreich erstellt' }, { root: true });
            return category;
          } catch (error) {
            commit('loading/set', false, { root: true });
            commit('error/set', error, { root: true });
          }
          return null;
        },
        async update({ commit, getters, rootGetters }, payload) {
          commit('loading/set', true, { root: true });
          try {
            await firebase
              .firestore()
              .collection('categories')
              .doc(rootGetters['category/get'].id)
              .collection('categories')
              .doc(getters.get.id)
              .update({
                updatedBy: firebase.firestore().doc(rootGetters['auth/get'].path),
                updatedAt: new Date(),
                ...payload,
              });
            commit('loading/set', false, { root: true });
            commit('category/dialog/toggleForm', false, { root: true });
            commit('snackbar/show', { text: 'Kategorie erfolgreich bearbeitet' }, { root: true });
          } catch (error) {
            commit('loading/set', false, { root: true });
            commit('error/set', error, { root: true });
          }
        },
        async updateSorting({ commit, rootGetters }, payload) {
          try {
            const batch = firebase.firestore().batch();

            await Promise.all(
              payload.map(async (id, index) => {
                const category = await firebase
                  .firestore()
                  .collection('categories')
                  .doc(rootGetters['category/get'].id)
                  .collection('categories')
                  .doc(id);
                batch.update(category, { sorting: (index + 1) * 10 });
              }),
            );
            await batch.commit();
            commit('snackbar/show', { text: 'Kategorien erfolgreich sortiert' }, { root: true });
          } catch (error) {
            commit('error/set', error, { root: true });
          }
        },
        async delete({ commit, getters, rootGetters }) {
          commit('loading/set', true, { root: true });

          try {
            await firebase
              .firestore()
              .collection('categories')
              .doc(rootGetters['category/get'].id)
              .collection('categories')
              .doc(getters.get.id)
              .delete();

            commit('loading/set', false, { root: true });
            commit('category/dialog/toggleDelete', false, { root: true });
            commit('snackbar/show', { text: 'Kategorie erfolgreich gelöscht' }, { root: true });
          } catch (error) {
            commit('loading/set', false, { root: true });
            commit('error/set', error, { root: true });
          }
        },
      },
      getters: {
        loading(state) {
          return state.loading;
        },
        get(state) {
          return state.category;
        },
        all(state) {
          return state.categories;
        },
      },
    },
  },
};
