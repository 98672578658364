import { AbilityBuilder } from '@casl/ability';
import _ from 'lodash';

const defineAbilitiesFor = (user) => {
  const { can, rules } = new AbilityBuilder();

  if (!user) {
    return rules;
  }

  if (_.includes(user.roles, 'admin')) {
    can(['highlight', 'publish'], 'Activity');
    can('create', 'User');
    can(['disable', 'delete'], 'User', { id: { $ne: user.id } });
    can(['create', 'delete'], 'Organization');
    can(['create', 'update', 'delete', 'publish'], 'Blog');
  }

  if (_.includes(user.roles, 'admin') && !user.organization) {
    can('show', 'Navigation');
    can('access', 'Admin');
  }

  can(['read', 'create', 'update', 'delete'], 'Activity');
  can(['read', 'create', 'update', 'delete'], 'Venue');
  can(['read', 'update'], 'Organization');
  can(['read', 'update', 'password'], 'User');

  // console.log(rules);
  return rules;
};

export default (store) => {
  const { ability } = store.getters;

  return store.subscribe((mutation) => {
    // eslint-disable-next-line default-case
    switch (mutation.type) {
      case 'auth/setAuth':
        ability.update(defineAbilitiesFor(mutation.payload));
        break;
    }
  });
};
