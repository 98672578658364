export default {
  namespaced: true,
  state: {
    mini: false,
    open: false,
  },
  mutations: {
    toggle(state, payload) {
      state.mini = payload;
    },
    open(state, payload) {
      state.open = payload;
    },
  },
  actions: {
    minimize({ commit }) {
      commit('toggle', true);
    },
    maximize({ commit }) {
      commit('toggle', false);
    },
    toggle({ commit, state }) {
      commit('toggle', !state.mini);
    },
    open({ commit, state }) {
      commit('open', !state.open);
    },
  },
  getters: {
    mini(state) {
      return state.mini;
    },
    open(state) {
      return state.open;
    },
  },
};
