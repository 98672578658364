import Vue from 'vue';
import router from '@/router';

const UrlPlugin = {
  install(vue) {
    // eslint-disable-next-line no-param-reassign
    vue.prototype.$url = (value) => {
      const params = router.app.$route.params || [];

      if (params.subOrganizationID) {
        return `/organization/${params.organizationID}/organization/${params.subOrganizationID}${value === '/' ? '' : value}`;
      }

      if (params.organizationID) {
        return `/organization/${params.organizationID}${value === '/' ? '' : value}`;
      }

      return value;
    };
  },
};

export default Vue.use(UrlPlugin);
