import Vue from 'vue';

const FormatParagraph = {
  install(vue) {
    // eslint-disable-next-line no-param-reassign
    vue.prototype.$formatParagraph = (value) => value.map((i) => i.filter(Boolean).join(' ')).filter(Boolean).join('<br />');
  },
};

export default Vue.use(FormatParagraph);
