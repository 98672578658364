<template>
  <div :class="className">
    <div class="headline">
      <slot />
    </div>
    <v-text-field v-model="searchValue" append-icon="mdi-magnify" label="Suchen" single-line hide-details clearable v-if="showSearch" />
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  props: ['value'],

  computed: {
    className() {
      const className = ['d-sm-flex', 'justify-space-between', 'base-title'];
      if (this.showSearch) {
        className.push('base-title-search');
      }
      return className.join(' ');
    },
    searchValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit('input', val);
      },
    },
    showSearch() {
      return !_.isUndefined(this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.base-title {
  margin-bottom: 20px;

  &.base-title-search {
    margin-bottom: 32px;
  }

  .v-text-field {
    max-width: 400px;
    padding-top: 0;
    margin-top: 0;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      margin-left: 20px;
    }
  }
}
</style>
