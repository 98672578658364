import firebase from 'firebase/app';
import router from '@/router';

export default {
  namespaced: true,
  state: {
    auth: null,
  },
  mutations: {
    setAuth(state, payload) {
      state.auth = payload;
    },
  },
  actions: {
    signIn({ commit, dispatch }, payload) {
      commit('loading/set', true, { root: true });
      commit('error/set', null, { root: true });

      firebase.auth().signInWithEmailAndPassword(payload.email, payload.password).then(({ user }) => firebase.firestore().collection('users').doc(user.uid).get()).then((snapshot) => {
        const {
          email, displayName, shortName, roles, organization,
        } = snapshot.data();
        commit('loading/set', false, { root: true });

        if (organization) {
          dispatch('organization/load', organization.id, { root: true });
        }

        commit('setAuth', {
          id: snapshot.ref.id, email, displayName, shortName, roles, path: snapshot.ref.path, organization: !!organization,
        });
        router.push('/');
      })
        .catch((error) => {
          if (error && error.code === 'auth/wrong-password') {
            // eslint-disable-next-line no-param-reassign
            error.message = 'E-Mail und Passwort passen nicht zusammen. Bitte überprüfe beides und versuche es erneut.';
          }
          if (error && error.code === 'auth/user-not-found') {
            // eslint-disable-next-line no-param-reassign
            error.message = 'Die eingegebenen Kontoangaben sind nicht korrekt.';
          }

          commit('loading/set', false, { root: true });
          commit('error/set', error, { root: true });
        });
    },
    autoSignIn({ commit, dispatch }, payload) {
      firebase.firestore().collection('users').doc(payload.uid).get()
        .then((snapshot) => {
          const {
            email, displayName, shortName, roles, organization,
          } = snapshot.data();

          if (organization) {
            dispatch('organization/load', organization.id, { root: true });
          }

          commit('setAuth', {
            id: snapshot.ref.id, email, displayName, shortName, roles, path: snapshot.ref.path, organization: organization ? organization.id : false,
          });
        });
    },
    async signUp({ commit }, payload) {
      commit('loading/set', true, { root: true });
      commit('error/clear', null, { root: true });

      const organizationRegister = firebase.functions().httpsCallable('organizationRegister');
      return organizationRegister({
        name: payload.name,
        address: payload.address,
        additional: payload.additional,
        zip: payload.zip,
        city: payload.city,
        email: payload.email,
        password: payload.password,
        website: payload.website,
        phone: payload.phone,
      })
        .then(() => {
          commit('loading/set', false, { root: true });
        })
        .catch((error) => {
          commit('loading/set', false, { root: true });
          commit('error/set', error, { root: true });
        });
    },
    signOut({ commit }) {
      firebase.auth().signOut().then(() => {
        commit('setAuth', null);
        commit('snackbar/close', false, { root: true });
        router.push('/auth/login');
      });
    },
    async forgot({ commit }, payload) {
      commit('loading/set', true, { root: true });
      commit('error/set', null, { root: true });

      return firebase.auth().sendPasswordResetEmail(payload.email).then(() => {
        commit('loading/set', false, { root: true });
      }).catch((error) => {
        commit('loading/set', false, { root: true });
        if (error.code === 'auth/user-not-found') {
          commit('error/set', new Error('Es wurde kein Benutzer mit dieser E-Mail-Adresse gefunden. Der Benutzer wurde möglicherweise gelöscht.'), { root: true });
        } else {
          commit('error/set', error, { root: true });
        }
      });
    },
    async resetPassword({ commit }, payload) {
      commit('loading/set', true, { root: true });
      commit('error/set', null, { root: true });

      return firebase.auth().confirmPasswordReset(payload.code, payload.password).then(() => {
        commit('loading/set', false, { root: true });
      }).catch((error) => {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
      });
    },
  },
  getters: {
    get(state) {
      return state.auth;
    },
  },
};
