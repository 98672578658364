import Vue from 'vue';
import CloudinaryVue from 'cloudinary-vue';
import { Cloudinary as CloudinaryCore } from 'cloudinary-core';

export default Vue.use(CloudinaryVue, {
  configuration: {
    cloudName: 'wow-wow',
  },
});

export const Cloudinary = new CloudinaryCore({ cloud_name: 'wow-wow' });
