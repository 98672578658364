import Vue from 'vue';
import Alert from '@/components/base/Alert.vue';
import ButtonMobile from '@/components/base/ButtonMobile.vue';
import Dashboard from '@/components/base/Dashboard.vue';
import Add from '@/components/base/Add.vue';
import ImageUpload from '@/components/base/ImageUpload.vue';
import Loading from '@/components/base/Loading.vue';
import Title from '@/components/base/Title.vue';

Vue.component('base-alert', Alert);
Vue.component('base-mobile-button', ButtonMobile);
Vue.component('base-dashboard', Dashboard);
Vue.component('base-card-add', Add);
Vue.component('base-image-upload', ImageUpload);
Vue.component('base-loading', Loading);
Vue.component('base-title', Title);
