export default {
  namespaced: true,
  state: {
    delete: false,
  },
  mutations: {
    delete(state, payload) {
      state.delete = payload;
    },
  },
  getters: {
    delete(state) {
      return state.delete;
    },
  },
};
