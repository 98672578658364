import Vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

import './plugins/bugsnag';
import './plugins/base';
import vuetify from './plugins/vuetify';
import fragment from './plugins/fragment';
import cloudinary from './plugins/cloudinary';
import GoogleMaps from './plugins/google-maps';
import './plugins/validate';
import url from './plugins/url';
import casl from './plugins/casl';
import formatParagraph from './plugins/formatParagraph';
import './plugins/vue-editor-js';

import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
});

const unsubscribe = firebase.auth().onAuthStateChanged((firebaseUser) => {
  new Vue({
    router,
    store,
    vuetify,
    fragment,
    cloudinary,
    url,
    casl,
    formatParagraph,
    GoogleMaps,
    render: (h) => h(App),
    created() {
      if (firebaseUser) {
        this.$store.dispatch('auth/autoSignIn', firebaseUser);
      }
    },
  }).$mount('#app');
  unsubscribe();
});
