import firebase from 'firebase/app';
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash';
import dialog from './dialog';

const defaultFormData = {
  name: '',
  address: '',
  zip: '',
  city: '',
  defaultVenue: false,
  geolocation: null,
};

export default {
  namespaced: true,
  modules: { dialog },
  state: {
    listenerUnsubscribe: [],
    loading: false,
    venue: null,
    venues: [],
    form: _.cloneDeep(defaultFormData),
  },
  mutations: {
    SET_LISTENER_UNSUBSCRIBE(state, payload) {
      state.listenerUnsubscribe.push(payload);
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setAll(state, payload) {
      state.venues = payload;
    },
    resetAll(state) {
      state.venues = [];
      state.listenerUnsubscribe.forEach((store) => {
        store();
      });
      state.listenerUnsubscribe = [];
    },
    set(state, payload) {
      state.venue = payload;
    },
    sync(state) {
      state.form = {
        name: state.venue.name,
        address: state.venue.address,
        zip: state.venue.zip,
        city: state.venue.city,
        defaultVenue: Boolean(state.venue.defaultVenue),
        geolocation: state.venue.geolocation || null,
      };
      // state.form = _.cloneDeep(state.activity);
    },
    reset(state) {
      state.venue = null;
      state.form = _.cloneDeep(defaultFormData);
    },
    updateField(state, field) {
      updateField(state.form, field);
    },
  },
  actions: {
    loadAllByOrganisation({ commit, rootGetters }) {
      commit('setLoading', true);
      const unsubscribe = firebase.firestore().collection('venues').where('organisation', '==', firebase.firestore().doc(rootGetters['organization/get'].path)).orderBy('name')
        .onSnapshot((snapshot) => {
          const data = [];
          snapshot.forEach((doc) => {
            const venue = {
              _type: 'Venue', id: doc.id, path: doc.ref.path, ...doc.data(),
            };
            data.push(venue);
          });
          commit('setAll', data);
          commit('setLoading', false);
        });
      commit('SET_LISTENER_UNSUBSCRIBE', unsubscribe);
    },
    async load({ commit }, payload) {
      try {
        const doc = await firebase.firestore().collection('venues').doc(payload).get();

        const data = {
          _type: 'Venue',
          id: doc.id,
          path: doc.ref.path,
          ...doc.data(),
        };

        commit('set', data);
      } catch (error) {
        commit('error/set', error, { root: true });
      }
    },
    async create({ commit, rootGetters }, payload) {
      commit('loading/set', true, { root: true });

      try {
        const venues = await firebase.firestore().collection('venues')
          .where('organisation', '==', firebase.firestore().doc(rootGetters['organization/get'].path))
          .where('address', '==', payload.address)
          .where('zip', '==', payload.zip)
          .where('city', '==', payload.city)
          .get();

        if (!venues.empty) {
          throw new Error('Es existiert bereits ein Ort mit derselben Adresse');
        }

        const venue = await firebase.firestore().collection('venues').add({
          organisation: firebase.firestore().doc(rootGetters['organization/get'].path),
          createdBy: firebase.firestore().doc(rootGetters['auth/get'].path),
          createdAt: new Date(),
          ...payload,
        });

        commit('loading/set', false, { root: true });
        commit('dialog/form', false);
        commit('snackbar/show', { text: 'Veranstaltungsort erfolgreich erstellt' }, { root: true });
        return venue;
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
        return null;
      }
    },
    async update({ commit, getters, rootGetters }, payload) {
      commit('loading/set', true, { root: true });

      try {
        const venues = await firebase.firestore().collection('venues')
          .where('organisation', '==', firebase.firestore().doc(rootGetters['organization/get'].path))
          .where('address', '==', payload.address)
          .where('zip', '==', payload.zip)
          .where('city', '==', payload.city)
          .get();

        if (!venues.empty) {
          const venuesExcludeCurrent = venues.docs.filter((venue) => venue.id !== getters.get.id);
          if (venuesExcludeCurrent.length > 0) {
            throw new Error('Es existiert bereits ein Ort mit derselben Adresse');
          }
        }

        if (payload.defaultVenue) {
          await getters.get.organisation.update({
            updatedBy: firebase.firestore().doc(rootGetters['auth/get'].path),
            updatedAt: new Date(),
            name: payload.name,
            address: payload.address,
            zip: payload.zip,
            city: payload.city,
          });

          commit('organization/set', {
            ...rootGetters['organization/get'],
            name: payload.name,
            address: payload.address,
            zip: payload.zip,
            city: payload.city,
          }, { root: true });
        }

        await firebase.firestore().collection('venues').doc(getters.get.id).update({
          updatedBy: firebase.firestore().doc(rootGetters['auth/get'].path),
          updatedAt: new Date(),
          ...payload,
        });

        commit('loading/set', false, { root: true });
        commit('dialog/form', false);
        commit('snackbar/show', { text: 'Veranstaltungsort erfolgreich bearbeitet' }, { root: true });
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
      }
    },
    async delete({ commit, rootGetters }, payload) {
      commit('loading/set', true, { root: true });

      try {
        const defaultVenue = await firebase.firestore().collection('venues')
          .where('organisation', '==', firebase.firestore().doc(rootGetters['organization/get'].path))
          .where('defaultVenue', '==', true)
          .get();

        if (!defaultVenue.empty) {
          const batch = firebase.firestore().batch();
          const activities = await firebase.firestore().collection('activities').where('venue', '==', firebase.firestore().doc(payload.path)).get();

          activities.forEach(async (doc) => {
            batch.update(doc.ref, { venue: firebase.firestore().doc(defaultVenue.docs[0].ref.path) });
          });
          await batch.commit();
        }

        await firebase.firestore().collection('venues').doc(payload.id).delete();

        commit('loading/set', false, { root: true });
        commit('dialog/delete', false);
        commit('snackbar/show', { text: 'Veranstaltungsort erfolgreich gelöscht' }, { root: true });
      } catch (error) {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
      }
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    get(state) {
      return state.venue;
    },
    field(state) {
      return getField(state.form);
    },
    all(state) {
      return state.venues;
    },
  },
};
