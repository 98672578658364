import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import de from 'vuetify/es5/locale/de';
import FeedbackIcon from '../components/base/FeedbackIcon.vue';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de',
  },
  icons: {
    iconfont: 'mdi',
    values: {
      feedback: {
        component: FeedbackIcon,
      },
    },
  },
  theme: {
    themes: {
      light: {
        primary: '#64A175',
      },
    },
  },
});
