export default {
  namespaced: true,
  state: {
    create: false,
    update: false,
    resetPassword: false,
    disable: false,
    delete: false,
  },
  mutations: {
    create(state, payload) {
      state.create = payload;
    },
    update(state, payload) {
      state.update = payload;
    },
    resetPassword(state, payload) {
      state.resetPassword = payload;
    },
    disable(state, payload) {
      state.disable = payload;
    },
    delete(state, payload) {
      state.delete = payload;
    },
  },
  getters: {
    create(state) {
      return state.create;
    },
    update(state) {
      return state.update;
    },
    resetPassword(state) {
      return state.resetPassword;
    },
    disable(state) {
      return state.disable;
    },
    delete(state) {
      return state.delete;
    },
  },
};
