import firebase from 'firebase/app';
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash';
import dialog from './dialog';

const defaultFormData = {
  displayName: '',
  email: '',
  phone: '',
  changePassword: true,
  roles: ['editor'],
};

export default {
  namespaced: true,
  modules: { dialog },
  state: {
    listenerUnsubscribe: [],
    loading: false,
    user: null,
    users: [],
    form: _.cloneDeep(defaultFormData),
  },
  mutations: {
    SET_LISTENER_UNSUBSCRIBE(state, payload) {
      state.listenerUnsubscribe.push(payload);
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setAll(state, payload) {
      state.users = payload;
    },
    resetAll(state) {
      state.users = [];
      state.listenerUnsubscribe.forEach((store) => {
        store();
      });
      state.listenerUnsubscribe = [];
    },
    set(state, payload) {
      state.user = payload;
    },
    sync(state) {
      state.form = {
        displayName: state.user.displayName,
        email: state.user.email,
        phone: state.user.phone,
        changePassword: state.user.changePassword,
        roles: state.user.roles,
      };
    },
    reset(state) {
      state.user = null;
      state.form = _.cloneDeep(defaultFormData);
    },
    updateField(state, field) {
      updateField(state.form, field);
    },
  },
  actions: {
    loadAll({ commit }) {
      commit('setLoading', true);
      const unsubscribe = firebase.firestore().collection('users').where('organization', '==', null).onSnapshot((snapshot) => {
        const usersArr = [];
        snapshot.forEach((doc) => {
          usersArr.push({
            _type: 'User', id: doc.id, path: doc.ref.path, ...doc.data(),
          });
        });
        commit('setAll', usersArr);
        commit('setLoading', false);
      });
      commit('SET_LISTENER_UNSUBSCRIBE', unsubscribe);
    },
    loadAllActive({ commit }) {
      commit('setLoading', true);
      const unsubscribe = firebase.firestore().collection('users').orderBy('displayName').onSnapshot((snapshot) => {
        const usersArr = [];
        snapshot.forEach((doc) => {
          const { disabled } = doc.data();
          if (!disabled) {
            usersArr.push({
              _type: 'User', id: doc.id, path: doc.ref.path, ...doc.data(),
            });
          }
        });
        commit('setAll', usersArr);
        commit('setLoading', false);
      });
      commit('SET_LISTENER_UNSUBSCRIBE', unsubscribe);
    },
    loadAllByOrganisation({ commit, rootGetters }) {
      commit('setLoading', true);
      const unsubscribe = firebase.firestore().collection('users').where('organization', '==', firebase.firestore().doc(rootGetters['organization/get'].path)).onSnapshot((snapshot) => {
        const usersArr = [];
        snapshot.forEach((doc) => {
          usersArr.push({
            _type: 'User', id: doc.id, path: doc.ref.path, ...doc.data(),
          });
        });
        commit('setAll', usersArr);
        commit('setLoading', false);
      });
      commit('SET_LISTENER_UNSUBSCRIBE', unsubscribe);
    },
    async load({ commit }, payload) {
      try {
        const doc = await firebase.firestore().collection('users').doc(payload).get();
        const data = doc.data();
        commit('set', {
          _type: 'User', id: doc.id, path: doc.ref.path, ...data,
        });
      } catch (error) {
        commit('error/set', error, { root: true });
      }
    },
    async create({ commit, rootGetters }, payload) {
      commit('loading/set', true, { root: true });

      const createUser = firebase.functions().httpsCallable('createUser');
      return createUser({
        displayName: payload.displayName,
        email: payload.email,
        phone: payload.phone,
        password: payload.password,
        changePassword: payload.changePassword,
        roles: payload.roles,
        organization: rootGetters['organization/get'] ? rootGetters['organization/get'].path : null,
      })
        .then(() => {
          commit('loading/set', false, { root: true });
          commit('dialog/create', false);
          commit('snackbar/show', { text: 'Benutzer erfolgreich erstellt' }, { root: true });
        })
        .catch((error) => {
          commit('loading/set', false, { root: true });
          commit('error/set', error, { root: true });
        });
    },
    async update({ commit, state }, payload) {
      commit('loading/set', true, { root: true });

      const updateUser = firebase.functions().httpsCallable('updateUser');
      return updateUser({
        uid: state.user.id,
        email: state.user.email,
        displayName: payload.displayName,
        phone: payload.phone,
        changePassword: payload.changePassword,
        roles: payload.roles,
      })
        .then(() => {
          commit('loading/set', false, { root: true });
          commit('dialog/update', false);
          commit('snackbar/show', { text: 'Benutzer erfolgreich bearbeitet' }, { root: true });
        })
        .catch((error) => {
          commit('loading/set', false, { root: true });
          commit('error/set', error, { root: true });
        });
    },
    async resetPassword({ commit }, payload) {
      commit('loading/set', true, { root: true });

      const resetPassword = firebase.functions().httpsCallable('resetPassword');
      return resetPassword({
        uid: payload.id,
        password: payload.password,
      })
        .then(() => {
          commit('loading/set', false, { root: true });
          commit('dialog/resetPassword', false);
          commit('snackbar/show', { text: 'Passwort erfolgreich zurückgesetzt' }, { root: true });
        })
        .catch((error) => {
          commit('loading/set', false, { root: true });
          commit('error/set', error, { root: true });
        });
    },
    async sendPasswordResetEmail({ commit }, payload) {
      commit('loading/set', true, { root: true });

      return firebase.auth().sendPasswordResetEmail(payload.email).then(() => {
        commit('loading/set', false, { root: true });
        commit('dialog/resetPassword', false);
        commit('snackbar/show', { text: 'E-Mail für die Passwortzurücksetzung wurde gesendet' }, { root: true });
      }).catch((error) => {
        commit('loading/set', false, { root: true });
        commit('error/set', error, { root: true });
      });
    },
    async disable({ commit }, payload) {
      commit('loading/set', true, { root: true });

      const disableUser = firebase.functions().httpsCallable('disableUser');
      return disableUser({
        uid: payload.id,
        status: payload.status,
      })
        .then(() => {
          commit('loading/set', false, { root: true });
          commit('dialog/disable', false);
          commit('snackbar/show', { text: `Benutzer erfolgreich ${payload.status ? 'deaktiviert' : 'aktiviert'}` }, { root: true });
        })
        .catch((error) => {
          commit('loading/set', false, { root: true });
          commit('error/set', error, { root: true });
        });
    },
    async delete({ commit }, payload) {
      commit('loading/set', true, { root: true });

      const deleteUser = firebase.functions().httpsCallable('deleteUser');
      return deleteUser({
        uid: payload.id,
      })
        .then(() => {
          commit('loading/set', false, { root: true });
          commit('dialog/delete', false);
          commit('snackbar/show', { text: 'Benutzer erfolgreich gelöscht' }, { root: true });
        })
        .catch((error) => {
          commit('loading/set', false, { root: true });
          commit('error/set', error, { root: true });
        });
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    get(state) {
      return state.user;
    },
    field(state) {
      return getField(state.form);
    },
    all(state) {
      return state.users;
    },
  },
};
