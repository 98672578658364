import geocoder from 'geocoder';

// eslint-disable-next-line import/prefer-default-export
export const geocode = (address, zip, city) => new Promise((res) => {
  const addressStr = `${address}, ${zip} ${city}, Schweiz`;
  geocoder.geocode(addressStr, (err, data) => {
    if (data.status === 'OK') {
      res({ lat: data.results[0].geometry.location.lat, lng: data.results[0].geometry.location.lng });
    }
    res(null);
  }, {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    region: 'CH',
    language: 'de',
  });
});
